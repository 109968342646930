import React from "react"
import { Container, ContentWithPaddingXl } from "../misc/Layouts"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings"


import profileImageSrc from "../../images/profile.webp"
import gallerieImageSrc from "../../images/Kontakt.jpg"

import gallerie01 from "../../images/newset01/DSC03338.png"
import gallerie02 from "../../images/newset01/DSC03373.png"
import gallerie03 from "../../images/newset01/DSC03396.png"
import gallerie04 from "../../images/newset01/DSC03345.png"

import { StaticImage } from "gatsby-plugin-image"

import { SectionDescription } from "../misc/Typography"
// import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import { IntersectionObserver } from "../Motion/IntersectionObserver"
import { FadeInUpBox } from "../Motion/FadeInUpBox"

const ContainerWithSpace = tw(Container)`mx-3 mt-2`

const HeadingContainer = tw.div`text-center`
const Subheading = tw(SubheadingBase)`mb-4`
const Heading = tw(SectionHeading)`font-emotion`
const Description = tw(SectionDescription)`mx-auto`

const Posts = tw.div`mt-12 flex flex-wrap -mr-3 relative`
const Post = tw.a`flex flex-col h-full bg-gray-200 rounded-sm`
const PostImage = styled.div`
  ${props =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-64 sm:h-80 bg-center bg-cover rounded-sm`}
`
const PostText = tw.div`flex-1 px-6 py-8 text-center`
const PostTitle = tw.h3`font-emotion text-2xl group-hocus:text-primary-500 text-primary-600 transition-all duration-300 `
const PostDescription = tw.p``
const AuthorInfo = tw.div`flex`
const AuthorImage = tw.img`w-12 h-12 rounded-full mr-3`
const AuthorTextInfo = tw.div`text-xs text-gray-600`
const AuthorName = tw.div`font-semibold mt-2`
const AuthorProfile = tw.div`pt-1 font-medium text-gray-700`

const PostContainer = styled.div`
  ${tw`relative z-20 mt-4 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props =>
    props.featured &&
    css`
      ${tw`w-full sm:w-full lg:w-2/3`}
      ${Post} {
        ${tw`sm:flex-row items-center sm:pr-3`}
      }
      ${PostImage} {
        ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 rounded-sm sm:rounded-t-sm sm:rounded-lg`}
      }
      ${PostText} {
        ${tw`pl-8 pr-5`}
      }
      ${PostTitle} {
        ${tw`text-2xl`}
      }
      ${PostDescription} {
        ${tw`mt-4 text-sm font-karla text-gray-700 leading-relaxed`}
      }
      ${AuthorInfo} {
        ${tw`mt-8 flex items-center text-gray-700`}
      }
      ${AuthorName} {
        ${tw`mt-0 font-bold text-gray-700 text-sm`}
      }
    `}
`

// const DecoratorBlob1 = tw(
//   SvgDotPatternIcon
// )`absolute bottom-0 left-0 w-32 h-32 mb-3 ml-3 transform -translate-x-1/2 translate-y-1/2 fill-current text-gray-500 opacity-50`;
// const DecoratorBlob2 = tw(
//   SvgDotPatternIcon
// )`absolute top-0 right-0 w-32 h-32 mt-16 mr-6 transform translate-x-1/2 -translate-y-1/2 fill-current text-gray-500 opacity-50`;

export default ({
  editableData,
  subheading = "",
  heading = "",
  description = "",
  posts = [
    {
      postImageSrc: gallerieImageSrc,
      authorImageSrc: { profileImageSrc },
      title: "Willkommen im Cafe Milch & Zucker",
      description:
        "Welcher Kuchen schmeckt dir am besten? Von Montag bis Sonntag bereiten wir jeden Tag den besten Kuchen für dich und deine Freunde zu. Wir freuen uns auf deinen Besuch!",
      authorName: "Judith Haller",
      authorProfile: "Inhaberin",
      url: "#",
      featured: true,
    },
    {
      postImageSrc: gallerie01,
      title: "Genieße deine Zeit bei uns",
      authorName: "",
      url: "#",
    },
    {
      postImageSrc: gallerie02,
      title: "Nur für dich",
      authorName: "",
      url: "#",
    },
    {
      postImageSrc: gallerie03,
      title: "Endlich wieder Zeit für deine Freunde",
      authorName: "",
      url: "#",
    },
    {
      postImageSrc: gallerie04,
      title: "Mit allen Sinnen genießen",
      authorName: " ",
      url: "#",
    },
  ],
}) => {
  return (
    <ContainerWithSpace>
      <ContentWithPaddingXl>
        <IntersectionObserver>
          <FadeInUpBox>
            <HeadingContainer>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{editableData.sectionTitle}</Heading>
              {description && <Description>{description}</Description>}
            </HeadingContainer>
          </FadeInUpBox>
        </IntersectionObserver>
        <IntersectionObserver>
          <FadeInUpBox>
            <Posts>
              {posts.map((post, index) => (
                <PostContainer featured={post.featured} key={index}>
                  <Post className="group" href={post.url}>
                    <PostImage imageSrc={post.postImageSrc} />
                    <PostText>
                      <PostTitle>{post.title}</PostTitle>
                      {post.featured && (
                        <PostDescription>{post.description}</PostDescription>
                      )}
                      <AuthorInfo>
                        {post.featured && (
                          <AuthorImage
                            width="54"
                            height="54"
                            src={profileImageSrc}
                            alt={post.authorName}
                          />
                        )}
                        <AuthorTextInfo>
                          <AuthorName>{post.authorName}</AuthorName>
                          {post.featured && (
                            <AuthorProfile>{post.authorProfile}</AuthorProfile>
                          )}
                        </AuthorTextInfo>
                      </AuthorInfo>
                    </PostText>
                  </Post>
                </PostContainer>
              ))}
              {/* <DecoratorBlob1 />
          <DecoratorBlob2 /> */}
            </Posts>
          </FadeInUpBox>
        </IntersectionObserver>
      </ContentWithPaddingXl>
    </ContainerWithSpace>
  )
}
