import React from "react"
import tw from "twin.macro"
import { css } from "styled-components/macro" //eslint-disable-line
import { Container as ContainerBase } from "../misc/Layouts.js"
import { IntersectionObserver } from "../Motion/IntersectionObserver"
import { FadeInUpBox } from "../Motion/FadeInUpBox"
import EmotionHeader, { Text, Content } from "../common"

const Container = tw(ContainerBase)`
  text-gray-800
  md:mx-3`

const PrimaryAction = tw.button`
    font-karla
    px-8 py-3 mt-10 
    text-sm sm:text-base sm:mt-16 
    bg-gray-100 text-primary-600  
    rounded-full shadow-xs transition-all duration-300 hocus:bg-primary-500 
    hocus:text-gray-100 focus:shadow-outline`

const div = {
  hidden: {
    opacity: 0.0,
  },
  visible: {
    opacity: 1,
  },
}

export default ({ editableData }) => {
  return (
    <Container>
      <Content>
        <IntersectionObserver>
          <FadeInUpBox>
            <EmotionHeader
              title={editableData.title}
              subTitle={editableData.subTitle}
            />
            <Text>
              <div>{editableData.body}</div>
            </Text>
            <PrimaryAction>Montag bis Freitag: 09:00 Uhr bis 17:30 Uhr</PrimaryAction>
            <PrimaryAction>Sa. und Sonntag: 10:00 Uhr bis 18:00 Uhr</PrimaryAction>
          </FadeInUpBox>
        </IntersectionObserver>
      </Content>
    </Container>
  )
}
